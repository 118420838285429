var globalFunc = {
	init: function(){
		globalFunc.resize();
	},

	get_biggest: function(elements){
		//get all elements with class and get the biggest box
		var biggest_height = 0;
		for ( var i = 0; i < elements.length ; i++ ){
			var element_height = $(elements[i]).outerHeight();
			//compare the height, if bigger, assign to variable
			if(element_height > biggest_height ) biggest_height = element_height;
		}
		return biggest_height;
	},

	resize: function(){
		var windowWidth = $(window).width();
		var windowHeight = $(window).height();

		// STICKY FOOTER
		var headerHeight = $('header').outerHeight();
		var footerHeight = $('footer').outerHeight();
		var footerTop = (footerHeight) * -1;
		// $('footer').css({marginTop: footerTop});
		// $('#main-wrapper').css({paddingBottom: footerHeight});

		// for vertically middle content
		$('.bp-middle').each(function() {
			var bpMiddleHeight = $(this).outerHeight() / 2 * - 1;
			$(this).css({marginTop: bpMiddleHeight});
		});

		// for equalizer
		$('.classname').css({minHeight: 0});
		var ClassName = globalFunc.get_biggest($('.classname'));
		$('.classname').css({minHeight: ClassName});
	},

	touch: function(){
		if (Modernizr.touch) {
			$('html').addClass('bp-touch');
		}
	},

	readMore: function(){

		$('.collapse').each(function(){

			var _this = $(this);
			
			var collapse = _this.height();
			var innerCollapse = _this.find('.collapses-inner').height();

			if(innerCollapse < collapse) {
				_this.next('.collapsed').hide();
				_this.addClass('no-collapse');
			} else {
				_this.next('.collapsed').show();
				_this.removeClass('no-collapse');
			}

		});
	},

	animated: function(){
		if(!Modernizr.touch){
			$('.fadeUp').appear(function(){
				var elem = $(this);
				var el = elem.find('> *');
				setTimeout(function(){
					TweenMax.set($(elem), {opacity: 1});
					TweenMax.set($(el), {opacity: 0, y: 50});
					TweenMax.staggerTo($(el), 0.7, {opacity: 1, y: 0, delay: 0.3, ease: Quart.easeOut }, 0.3);
				}, 100);
			});

			$('.fadeLeftSelf').appear(function(){
				var elem = $(this);
				setTimeout(function(){
					TweenMax.set($(elem), {opacity: 0, x: 100});
					TweenMax.staggerTo($(elem), 0.7, {opacity: 1, x: 0, delay: 0.3, ease: Quart.easeOut }, 0.3);
				}, 100);
			});

			$('.fadeLeft').appear(function(){
				var elem = $(this);
				var el = elem.find('> *');
				setTimeout(function(){
					TweenMax.set($(elem), {opacity: 1});
					TweenMax.set($(el), {opacity: 0, x: 100});
					TweenMax.staggerTo($(el), 0.7, {opacity: 1, x: 0, delay: 0.3, ease: Quart.easeOut }, 0.3);
				}, 100);
			});
			$('.fadeRight').appear(function(){
				var elem = $(this);
				var el = elem.find('> *');
				setTimeout(function(){
					TweenMax.set($(elem), {opacity: 1});
					TweenMax.set($(el), {opacity: 0, x: -100});
					TweenMax.staggerTo($(el), 0.7, {opacity: 1, x: 0, delay: 0.3, ease: Quart.easeOut }, 0.3);
				}, 100);
			});
			$('.fadeIn').appear(function(){
				var elem = $(this);
				var el = elem.find('> *');
				setTimeout(function(){
					TweenMax.set($(elem), {opacity: 1});
					TweenMax.set($(el), {opacity: 0});
					TweenMax.staggerTo($(el), 0.7, {opacity: 1, delay: 0.3, ease: Quart.easeOut }, 0.3);
				}, 100);
			});
		}
	},

	burgerMenu: function(){
		$('.burger-menu').click(function(){
			$(this).toggleClass('active');
			$('.nav').slideToggle(200);
		});
	},

	backTop: function(){
		$('.back-top').click(function(){
			$('html,body').animate({ scrollTop: 0}, 700);
		});
	},

	customUpload: function(){
		$(".custom-file-input").on("change", function() {
		  var fileName = $(this).val().split("\\").pop();
		  $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
		});
	},

	quantityCount: function(){
		$('.q-count').on('change', function(){
			var _this = $(this);
			var val = _this.val();
			var amount = _this.closest('.my-order').find('.amount').text();
			var fee = amount * val;

			_this.parents('.my-order').find('.fee').text(fee+'.00');

			var sum = 0;
			$('.fee').each(function(){
				sum += Number($(this).text());
			});
			// return sum;
			$('.totals').text(sum+'.00');
		});
	},

	carousel: function(){

		$('#wineCarousel').carousel({
			interval: false,
      wrap: false
		});
    
    var checkitem = function() {
			var $this;
			$this = $("#wineCarousel");
			if ($("#wineCarousel .carousel-inner .carousel-item:first").hasClass("active")) {
				$this.children(".carousel-control-prev").addClass('inactive');
				$this.children(".carousel-control-next").removeClass('inactive');
			} else if ($("#wineCarousel .carousel-inner .carousel-item:last").hasClass("active")) {
				$this.children(".carousel-control-next").addClass('inactive');
				$this.children(".carousel-control-prev").removeClass('inactive');
			} else {
				$this.children(".carousel-control").show();
			}
		};
		
		checkitem();
		
		$("#wineCarousel").on("slid.bs.carousel", "", checkitem);
	}

};

$(window).resize(function() {
	globalFunc.init();
});

$(document).ready(function() {
	globalFunc.touch();
	globalFunc.init();
	globalFunc.readMore();
	globalFunc.burgerMenu();
	globalFunc.backTop();
	globalFunc.customUpload();
	globalFunc.quantityCount();
	globalFunc.carousel();
});

$(window).on('load', function() {
	globalFunc.init();
	globalFunc.animated();
});

// preloader once done
Pace.on('done', function() {
	// totally hide the preloader especially for IE
	setTimeout(function() {
		$('.pace-inactive').hide();
	}, 500);
});

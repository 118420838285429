$(window).on('load', function(){
  // Read the cookie and if it's defined scroll to id
  var scroll = localStorage.getItem('scroll');
  if(scroll){
     scrollToID(scroll, 1000);
    localStorage.clear('scroll');
  }

  // Handle event onclick, setting the cookie when the href != #
  var autoScroll = $('.autoscroll').find('a');
  autoScroll.click(function (e) {
    e.preventDefault();
    var id = $(this).data('id');
    var href = $(this).attr('href');

    if(href === '#'){
      scrollToID(id, 1000);
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?'+id;
      window.history.pushState({path:newurl},'',newurl);
    }else{
      localStorage.setItem('scroll', id);
      window.location.href = href+'?'+id;
    }



  });
});

// scrollToID function
function scrollToID(id, speed) {
  // var offSet = $('header').outerHeight() + 20;
  var obj = $('#' + id).offset();
  var targetOffset = obj.top;
  $('html,body').animate({ scrollTop: targetOffset }, speed);
}
